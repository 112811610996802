@import url('https://fonts.googleapis.com/css2?family=Afacad+Flux:wght@100..1000&family=Bebas+Neue&family=Protest+Strike&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sixtyfour+Convergence&display=swap');




.wrapper{
    width: 340px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vh;
    color: #fff;
    border-radius: 10px;
    font-family:"Afacad Flux", sans-serif;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    border: 2px solid rgba(255,255,255,0.2);
    padding: 1rem 1rem 0 1rem;
}

h1{
    font-size: 2.5rem;
    margin: 0;
}

.wrapper .input-box {
    width:100%;
    height: 3.5rem;
    margin: 1rem 0 1.2rem 0;
    display: flex;
}
.wrapper .input-box input {
    width: 100%;
    height: 100%;
    outline: none;
    border: 2px solid rgba(255,255,255,0.2);
    border-radius: 40px;
    background-color: rgba(0,0,0,0.1);
    color: #fff;
    font-size: 1.25rem;
    padding: 0 1rem;

}

.input-box input::placeholder {
    color: #fff
}

.input-box input:disabled{
    border: 2px solid rgba(255,255,255,0.1);
    background-color: rgba(0,0,0,0.3);
}

.input-box input:disabled::placeholder{
    color: #bbbbbb;
}


.wrapper .btn {
    width: 75%;
    height: 3rem;
    background-color: #fff;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 1.5rem;
    color: #333;
    font-weight: 600;
}

.wrapper .btn:hover{
    background-color: #efefef;
}
.login-link{
    margin: 1rem;
}
.login-link a{
    font-size: 1rem;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}
.login-link a:hover{
    text-decoration: underline;
    color:rgb(161, 41, 168)
}
.forgot-link{
    margin-bottom: 1rem;
}
.forgot-link a{
    font-size: 1rem;
    color: #ffffffa3
}
.forgot-link a:hover{
    color:rgb(161, 41, 168)
}


.display-error {
    margin-top: 0.2rem;
    background-color:rgb(214, 28, 28);
    font-size: 1rem;
    padding: 0.3rem;
    border-radius: 10px;
    width: max-content;
    margin-right: auto;
    margin-left: auto;
}

.display-message {
    margin: 1rem;
    font-size: 1.8rem;
    font-weight: 600;

}

.input-wrapper{
    margin: 2rem 0 3rem 0;
}

.input-wrapper .description{
    text-align: left;
    margin-left: 1rem;
    font-size:  1.1rem;
}

