.background {
    background-image: url('https://el.phncdn.com/pics/gifs/034/913/992/(m=ldpwiqacxtE_Ai)(mh=s7o0W7TLsTinDGS_)34913992b.gif');
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position:absolute;
  }
  