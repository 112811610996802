@import url('https://fonts.googleapis.com/css2?family=Afacad+Flux:wght@100..1000&family=Protest+Strike&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sixtyfour+Convergence&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Afacad+Flux:wght@100..1000&family=Bebas+Neue&family=Protest+Strike&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sixtyfour+Convergence&display=swap');


.protest-strike-regular {
  font-family: "Protest Strike", sans-serif;
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  background-color: #030c1c;
  justify-content:center;
  font-family: "Afacad Flux", sans-serif;
}


.App {
  text-align: center;
  position: relative;
  align-items: center;
  justify-content: center;
}

.board {
  padding: 3vh 3vh;
  position: relative;
  display: flex;
  margin-top: 2vh;
  margin-bottom: 1vh;
  min-width: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.GlowingTitle {
  width: 100%;
  font-size: 9vh;
  font-family: "Bebas Neue", sans-serif;
  color: #ffd9e2;
  white-space: pre;
  position: relative;
  text-shadow: 0 0 0 transparent, 0 0 10px #ED3EF7,0 0 20px rgba(255, 0, 60, 0.5),0 0 40px #ED3EF7, 0 0 300px #ED3EF7, 0 0 20px #ED3EF7, 0 0 30px #ED3EF7, 0 0 50px #ED3EF7;
  animation: blinking 0.02s infinite alternate;
}

.Title {
  font-size: 3rem;
  font-family: "Bebas Neue", sans-serif;
  color: #ffd9e2;
}

.GlowingText {
  padding: 3vh 3vh;
  width: 100%;
  font-size: 3.5vh;
  font-family: "Afacad Flux", sans-serif;
  color: #ffd9e2;
  white-space: pre;
  position: relative;
  text-shadow: 0 0 0 transparent, 0 0 5px #ff003c,0 0 10px rgba(255, 0, 60, 0.5),0 0 20px #ff003c, 0 0 50px #ff003c, 0 0 20px #ff003c, 0 0 15px #ff003c, 0 0 25px #ff003c;
  animation: flickering 5s infinite alternate;
}

.singlebtn {
  position: relative;
  width: fit-content;
  background-color: #3333337d;
  border: 2px solid #d7d7d7;
  outline: none;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  cursor: pointer;
  font-size: 1rem;
  color: #ffffff;
  font-weight: 600;
  padding: 0.2rem 1rem;
}
.singlebtn:hover{
  background-color: #333333b0;
}

@keyframes blinking {
  75% {
    opacity: 0.90;
  }
}

@keyframes flickering {
  40% {
    opacity: 1;
  }
  42% {
    opacity: 0.8;
  }
  43% {
    opacity: 1;
  }
  45% {
    opacity: 0.2;
  }
  46% {
    opacity: 1;
  }

}
